<template>
  <ModelDialog
    :visible="visible"
    :title="title"
    isfooter
    @handleOk="handleOk"
    @handleNo="handleNo"
    handleoklbut="确认选择"
  >
    <div class="good_list">
      <el-row :gutter="20">
        <el-col :span="6" ref="dom">
          <div class="grid-content bg-purple">
            <el-form label-width="0">
              <el-form-item>
                <el-input
                  v-model="pages.base_name"
                  size="small"
                  placeholder="输入商品名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="pages.brand_id"
                  size="small"
                  clearable
                  style="width: 100%"
                  filterable
                  placeholder="请选择品牌"
                >
                  <el-option
                    v-for="item in form.brandSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="pages.status"
                  size="small"
                  clearable
                  style="width: 100%"
                  filterable
                  placeholder="全部状态"
                >
                  <el-option
                    v-for="item in form.statesSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-cascader
                  ref="cascader"
                  style="width: 100%"
                  size="small"
                  @change="typeChange('main')"
                  placeholder="请选择主分类"
                  clearable
                  :props="propsType"
                ></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-cascader
                  ref="cascaderAffiliate"
                  style="width: 100%"
                  size="small"
                  @change="typeChange('affiliate')"
                  placeholder="请选择附属分类"
                  clearable
                  :props="propsType"
                ></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="pages.codenos"
                  size="small"
                  clearable
                  placeholder="批量输入商品编号,用逗号隔开"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="pages.tags"
                  size="small"
                  clearable
                  placeholder="输入商品标签,用逗号隔开,最多10个"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="pages.order_buy_counts"
                  size="small"
                  clearable
                  style="width: 100%"
                  placeholder="销量排序"
                >
                  <el-option
                    v-for="item in form.orderSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button size="small" style="width: 100%" @click="getDataList"
                  >搜索商品</el-button
                >
              </el-form-item>
            </el-form>
            <!--确认选择-->
            <div class="seach_btnOK">
              <p>已选中{{ good_list.length }}个商品</p>
              <div class="chack_goodlist">
                <div v-for="(item, index) in good_list" :key="index">
                  <el-image style="max-width: 50px; max-height: 50px" :src="item.image" fit="fill">
                    <template #error>
                      <div class="image-slot">
                        <img src="../../assets/img/goodserro.png" alt="" />
                      </div>
                    </template>
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple">
            <el-table
              :data="dataList"
              :header-cell-style="{ 'background-color': '#f6f6f6', color: '#333' }"
              v-loading="loading"
              :row-key="getRowKeys"
              :height="tableHeight"
              @row-click="rowClick"
              @selection-change="changeSelection"
            >
              <template #empty>
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column reserve-selection type="selection" v-if="chooseType === 'multiple'">
              </el-table-column>
              <el-table-column v-else width="40px">
                <template #default="tableItem">
                  <el-radio
                    v-model="radio"
                    :label="tableItem.row.id"
                    @change="radioChange(tableItem.row, tableItem.$index)"
                  ></el-radio>
                </template>
              </el-table-column>
              <el-table-column label="商品">
                <template #default="tableItem">
                  <div class="shoopy_list">
                    <div class="shoppy_img">
                      <el-image
                        style="width: 70px; max-height: 90px; border-radius: 5px"
                        :src="tableItem.row.image"
                        fit="fill"
                      >
                        <template #error>
                          <div class="image-slot">
                            <img src="../../assets/img/goodserro.png" alt="" />
                          </div>
                        </template>
                      </el-image>
                    </div>
                    <div>
                      <p class="base_name" :title="tableItem.row.base_name">
                        {{ tableItem.row.base_name }}
                      </p>
                      <p class="number">{{ tableItem.row.category_name }}</p>
                      <p class="number" v-show="tableItem.row.status === 0">
                        <el-tag type="info" size="small">下架</el-tag>
                      </p>
                      <p class="number" style="color: #8b8b8b">{{ tableItem.row.codeno }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="价格" width="220px">
                <template #default="tableItem">
                  <span style="color: #000; font-weight: bold"> {{ tableItem.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column label="销量" prop="buy_counts" width="150px"></el-table-column>
            </el-table>
            <div class="m-table-pagination-wrap">
              <Pagination
                :page-size="pages.page_size"
                :total="pages.total"
                v-model:page="pages.page"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </ModelDialog>
</template>

<script>
import ModelDialog from '@/components/modelDialog/Index.vue';
import Pagination from '@/components/pagInation/Index.vue';
import { getList, getBrandsAndTypes, Ajaxcategorypath } from './api/selectgoods';
import { ref } from 'vue';
export default {
  name: 'Index',
  components: {
    ModelDialog,
    Pagination,
  },
  props: {
    visible: {
      //是否显示model
      type: Boolean,
      default: false,
    },
    spec_mode: {
      type: String,
      default: '0,1', //0  单规格  1  多规格  2  diy
    },
    chooseType: {
      //数据选择类型"multiple"多选 ,"radio"单选
      type: String,
      default: 'multiple',
    },
    title: {
      type: String,
      default: '请选择参与活动的商品',
    },
  },
  setup(props, context) {
    const form = ref({
      name: '',
      statesSelect: [
        {
          value: '',
          label: '全部状态',
        },
        {
          value: '0',
          label: '已下架',
        },
        {
          value: '1',
          label: '上架中',
        },
      ],
      orderSelect: [
        {
          value: '-1',
          label: '默认',
        },
        {
          value: 'asc',
          label: '销量升序',
        },
        {
          value: 'desc',
          label: '销量降序',
        },
      ],
      brandSelect: [
        {
          value: '',
          label: '请选择品牌',
        },
      ],
    });
    const dataList = ref([]);
    const loading = ref(false);
    const tableHeight = ref(0); //tab高度
    const cascader = ref(); //主分类
    const cascaderAffiliate = ref(); //附属分类
    const pages = ref({
      page: 1,
      page_size: 10,
      total: 0,
      notgoodsids: '', //排除商品 id
      sale_mode: '', //销售类型
      base_name: '', //商品名称  模糊查询
      codenos: '', //商品编号  逗号拼接
      is_free_shipping: '', //是否包邮
      status: '', //上下架
      brand_id: '', //商品品牌
      type_id: '',
      category_id: '', //分类id
      start_time: '', //开始时间
      end_time: '', //结束时间
      type: '', //可以理解为来源
      tags: '', //商品标签 逗号分隔
      rel_id: '', // 附属分类
      order_buy_counts: '', //排序 desc  /  asc
      goods_ids: '', //已选 商品id
    });
    const radio = ref('');
    const radioData = ref(''); // 单选选中数据
    const multipleData = ref([]); //多选选中数据
    const good_list = ref([]); //商品已选中数量
    function handleOk() {
      let data = props.chooseType === 'multiple' ? multipleData : radioData;
      context.emit('close');
      context.emit('selectOk', data); //选择数据后的回调
    }
    function handleNo() {
      context.emit('close');
    }
    function getRowKeys(row) {
      return row.id;
    }
    function rowClick(row) {
      //当前行点击事件
      if (props.chooseType === 'multiple') {
        //多选
      } else {
        //单选
        radio.value = row.id;
        radioChange(row);
      }
    }
    //多选回调
    function changeSelection(row) {
      good_list.value = [];
      good_list.value = row;
      multipleData.value = row;
    }
    //单选回调
    function radioChange(row) {
      radioData.value = row;
      good_list.value = [];
      good_list.value.push({
        image: row.image,
      });
    }
    async function getDataList() {
      loading.value = true;
      let parms = {
        page_size: pages.value.page_size,
        page: pages.value.page,
        notgoodsids: pages.value.notgoodsids, //排除商品 id
        sale_mode: pages.value.sale_mode, //销售类型
        base_name: pages.value.base_name, //商品名称  模糊查询
        codenos: pages.value.codenos, //商品编号  逗号拼接
        is_free_shipping: pages.value.is_free_shipping, //是否包邮
        status: pages.value.status, //上下架
        brand_id: pages.value.brand_id, //商品品牌
        type_id: pages.value.type_id,
        category_id: pages.value.category_id, //分类id
        start_time: pages.value.start_time, //开始时间
        end_time: pages.value.end_time, //结束时间
        type: pages.value.type, //可以理解为来源
        tags: pages.value.tags, //商品标签 逗号分隔
        order_buy_counts: pages.value.order_buy_counts, //排序 desc  /  asc
        goods_ids: pages.value.goods_ids, //已选 商品id
        rel_id: pages.value.rel_id, // 附属分类
        spec_mode: props.spec_mode,
      };
      const { data } = await getList(parms);
      data.res.map(res => {
        res.price =
          data.base_currency.symbol_left + ' ' + res.price + ' ' + data.base_currency.symbol_right;
      });
      pages.value.total = data.pages.total;
      dataList.value = data.res;
      loading.value = false;
    }
    //获得品牌数据
    async function BrandsSelect() {
      const { data } = await getBrandsAndTypes();
      data.brands.map(res => {
        form.value.brandSelect.push({
          value: res.id,
          label: res.base_name,
        });
      });
    }
    function handleCurrentChange(val) {
      pages.value.page = val;
      getDataList();
    }
    function handleSizeChange(val) {
      pages.value.page_size = val;
      getDataList();
    }
    function typeChange(type) {
      if (type === 'main') {
        //主分类
        cascader.value.getCheckedNodes().map(res => {
          pages.value.category_id = res.value;
        });
      } else if (type === 'affiliate') {
        //附属分类
        cascaderAffiliate.value.getCheckedNodes().map(res => {
          pages.value.rel_id = res.value;
        });
      }
    }
    const propsType = ref({
      lazy: true,
      checkStrictly: true,
      lazyLoad(node, resolve) {
        const id = node ? node.data.value : '';
        const params = {
          id: id,
        };
        Ajaxcategorypath(params)
          .then(res => {
            const goods_data = [];
            const { data } = res;
            data.list.map(res => {
              goods_data.push({
                value: res.id,
                label: res.base_name,
                leaf: data.leaf === 'false' ? false : true,
              });
            });
            resolve(goods_data);
          })
          .catch(() => {
            resolve([]);
          });
      },
    });
    return {
      handleOk,
      handleNo,
      changeSelection,
      handleCurrentChange,
      getRowKeys,
      getDataList,
      rowClick,
      radioChange,
      BrandsSelect,
      typeChange,
      handleSizeChange,
      form,
      pages,
      cascader,
      cascaderAffiliate,
      good_list,
      radioData,
      dataList,
      radio,
      propsType,
      tableHeight,
      multipleData,
      loading,
    };
  },
  mounted() {
    this.getDataList();
    this.$nextTick(function () {
      this.tableHeight = this.$refs.dom.$el.offsetHeight; //动态赋值table高度
    });
    setTimeout(() => {
      this.BrandsSelect(); //加载品牌数据
    }, 1500);
  },
};
</script>
<style scoped lang="scss">
.good_list {
  min-height: 100%;
  .el-form-item {
    margin-bottom: 5px;
  }
  .seach_btnOK {
    p {
      text-align: center;
      color: #000;
      padding: 10px 0;
    }
  }
  .chack_goodlist {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    width: 100%;
    > div {
      flex: 0 0 33.33333%;
      height: 50px;
      margin-bottom: 5px;
      text-align: center;
    }
  }
  .shoopy_list {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      flex: 1;
      p {
        color: #000;
      }
      .base_name {
        overflow: hidden;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .number {
        padding: 5px 0 3px 0;
        box-sizing: border-box;
      }
    }
    .shoppy_img {
      flex: 0 0 80px;
    }
  }
}
.m-table-pagination-wrap {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
::v-deep(.el-radio__label) {
  display: none;
}
::v-deep(.el-table--enable-row-transition .el-table__body td) {
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  ::v-deep(.el-dialog__body) {
    padding: 5px 10px;
    min-height: auto;
  }
  ::v-deep(.el-dialog__header) {
    padding: 10px;
  }
  .good_list .el-form-item {
    margin-bottom: 0;
  }
  ::v-deep(.el-table td) {
    padding: 5px 0;
    font-size: 12px;
  }
  ::v-deep(.el-dialog__footer) {
    padding: 5px;
  }
  ::v-deep(.el-table th) {
    padding: 5px 0;
  }
  ::v-deep(.el-button--small) {
    padding: 4px 8px;
    min-height: 30px;
  }
  .el-image {
    max-width: 50px;
    max-height: 50px;
  }
  .m-table-pagination-wrap {
    height: 40px;
  }
}
</style>
