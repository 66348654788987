<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      :width="width"
      v-model="dialogVisible"
      :append-to-body="append"
      :fullscreen="fullscreen"
      :close-on-press-escape="closeEscape"
    >
      <template #title>
        <div class="modeltitle">
          <span>{{ title }}</span>
          <span>
            <!--            <i class="el-icon-copy-document" @click="full" />-->
            <i class="el-icon-close" @click="handleNo" />
          </span>
        </div>
      </template>
      <slot />
      <template #footer v-if="isfooter">
        <div>
          <span class="dialog-footer">
            <el-button v-if="iscancelbut" size="small" @click="handleNo">{{ cancelbut }}</el-button>
            <el-button v-if="ishandleoklbut" size="small" type="primary" @click="handleOk">{{
              handleoklbut
            }}</el-button>
          </span>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  name: 'ModelDialog',
  props: {
    /**
     * 弹框参数
     * */
    // 打开关闭参数
    visible: {
      type: Boolean,
      defaults: false,
    },
    isclose: {
      // 是否自定义关闭事件
      type: Boolean,
      defaults: false,
    },
    closeEscape: {
      // 是否可以通过按下 ESC 关闭 Dialog
      type: Boolean,
      defaults: true,
    },
    title: {
      type: String,
      default: '标题',
    },
    append: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '1100px',
    },
    // 自定义按钮
    custombutton: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /**
     * 按钮参数
     * */
    isfooter: {
      type: Boolean,
      default: false,
    },
    cancelbut: {
      type: String,
      default: '取消',
    }, // 取消按钮的名称
    handleoklbut: {
      type: String,
      default: '保存',
    }, // 保存按钮的名字
    // 是否取消取消按钮
    iscancelbut: {
      type: Boolean,
      default: true,
    },
    // 是否取消保存按钮
    ishandleoklbut: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const dialogVisible = ref(props.visible);
    const fullscreen = ref(false);
    function handleNo() {
      //取消事件
      context.emit('handleNo');
    }
    function handleOk() {
      //保存事件
      context.emit('handleOk');
    }
    return {
      dialogVisible,
      fullscreen,
      handleNo,
      handleOk,
    };
  },
  watch: {
    visible(obj) {
      if (!obj) {
        this.close();
      }
    },
  },
  methods: {
    full() {
      // this.fullscreen
      if (this.fullscreen === false) {
        this.fullscreen = true;
      } else {
        this.fullscreen = false;
      }
    },
    // 自定义按钮回调
    handleCustom(item) {
      this.$emit('handleCustom', item);
    },
  },
};
</script>
<style>
.el-dialog__footer {
  width: 100%;
  border-top: 1px solid #ebebeb;
}
.el-dialog__footer button {
  min-width: 100px;
}
</style>
<style lang="scss" scoped>
.modeltitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  i {
    margin: 0 5px;
    cursor: pointer;
  }
  i:last-child {
    margin: 0;
  }
}
</style>
